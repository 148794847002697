import {Dispatch} from 'redux'
import {ControllerParams} from '@wix/yoshi-flow-editor'
import {onInstanceChanged} from '../services/instance'

export const SET_INSTANCE = 'SET_INSTANCE'

const setInstance = (instance: string) => ({
  type: SET_INSTANCE,
  payload: {
    instance,
  },
})

export const watchInstance = (controller: ControllerParams['controllerConfig'], dispatch: Dispatch) =>
  onInstanceChanged(controller, instance => dispatch(setInstance(instance)))
