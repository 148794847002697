import {createAsyncAction} from '../services/redux-toolkit'

export const SHARE_EVENT = 'SHARE_EVENT'

interface CreateEventParams {
  data: {
    event: wix.events.EventData
    language: string
    timezone: string
    start: string
    end: string
  }
  ADI: boolean
}

export const createEvent = createAsyncAction<{event: wix.events.Event; ADI: boolean}, CreateEventParams>(
  'CREATE_EVENT',
  ({data, ADI}, {extra: {serverApi}}) => serverApi.createEvent(data, ADI),
)

export const shareEvent = (eventId: string, buttonName: string) => ({
  type: SHARE_EVENT,
  payload: {
    eventId,
    buttonName,
  },
})
