import {AnyAction} from 'redux'
import {
  SET_CALENDAR_REFERENCE_DATE,
  CLOSE_MONTHLY_CALENDAR_EVENT,
  CLOSE_MONTHLY_CALENDAR_POPUP,
  OPEN_MONTHLY_CALENDAR_EVENT,
  OPEN_MONTHLY_CALENDAR_POPUP,
} from '../actions/calendar-layout'
import {loadCalendarEvents} from '../actions/events'
import {CalendarLayout} from '../types/state'

const defaultState: CalendarLayout = {
  referenceDate: null,
  today: null,
  month: {},
  monthly: {
    selectedDate: null,
    selectedEventId: null,
  },
  days: {},
  weekDayNames: [],
  loadedPages: [],
  events: {},
  demoEvents: {},
  loading: null,
}

export const calendarLayout = (state = defaultState, action: AnyAction): CalendarLayout => {
  switch (action.type) {
    case SET_CALENDAR_REFERENCE_DATE:
      return {
        ...state,
        referenceDate: action.payload,
        monthly: {
          ...state.monthly,
          selectedDate: null,
          selectedEventId: null,
        },
      }
    case loadCalendarEvents.pending.toString():
      if (loadCalendarEvents.pending.match(action)) {
        return {
          ...state,
          loading: action.meta.arg.origin,
        }
      }

      return state
    case loadCalendarEvents.fulfilled.toString(): {
      if (loadCalendarEvents.fulfilled.match(action) && !action.payload) {
        return {
          ...state,
          loading: null,
        }
      }

      if (loadCalendarEvents.fulfilled.match(action) && action.payload) {
        const reset = action.meta.arg.fullReset
        const {referenceDate, weekDayNames, month, today, events, days} = action.payload.dates.calendar

        return {
          ...state,
          referenceDate,
          today,
          monthly: {
            ...state.monthly,
            selectedDate: null,
            selectedEventId: null,
          },
          weekDayNames,
          month: {
            ...state.month,
            ...month,
          },
          events: reset ? events : {...state.events, ...events},
          days: {...state.days, ...days},
          loadedPages: reset ? [referenceDate] : [...state.loadedPages, referenceDate],
          loading: null,
        }
      }

      return state
    }
    case loadCalendarEvents.rejected.toString():
      if (loadCalendarEvents.rejected.match(action)) {
        return {
          ...state,
          loading: null,
        }
      }

      return state
    case OPEN_MONTHLY_CALENDAR_POPUP:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: action.payload.day,
          selectedEventId: null,
        },
      }
    case CLOSE_MONTHLY_CALENDAR_POPUP:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: null,
        },
      }
    case OPEN_MONTHLY_CALENDAR_EVENT:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: action.payload.day,
          selectedEventId: action.payload.eventId,
        },
      }
    case CLOSE_MONTHLY_CALENDAR_EVENT:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedEventId: null,
        },
      }
    default:
      return state
  }
}
